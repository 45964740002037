
@font-face {
  font-family: "JetBrains Mono";
  src: url(fonts/JetBrainsMono-Regular.woff2) format("woff2");
}
body {
  margin: 0;
  font-family: "JetBrains Mono", Consolas, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "JetBrains Mono", Consolas, monospace;
}
