.navBar {
  background-color: #222;
  right: 0;
  left: 0;
  z-index: 1030;
  border-radius: 0;
}

.navBarBrand {
  position: sticky;
  top: 0;
  display: block;
  max-width: 200px;
  padding: 15px 15px;
  margin-right: auto;
  margin-left: auto;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #777;
  text-align: center;
}

.navBarBrand a {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-decoration: none;
  color: #777;
}

.navBarBrand a:hover {
  color: #fff;
}
